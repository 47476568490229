import React, { useEffect, useState } from 'react';
import Select, { ValueType, ActionMeta } from 'react-select';

import { useFormState } from 'utils/customHooks';
import copyToClipboard from 'utils/copyToClipboard';
import formStyles from 'styles/forms.module.scss';
import selectStyles from 'styles/selectStyles';
import { OptionType, CredentialsState } from '_types';
import { ReactComponent as CopyIcon } from 'assets/svg/Copy.svg';
import styles from '../appPage.module.scss';

export const INITIAL_STATE_CREDENTIAL = {
  paymentGateway: { value: 'solid', label: 'solid' },
  wallet: '',
  merchant: '',
  secretKey: '',
  solidGateKey: '',
  solidGateMerchant: '',
  apiBaseUrl: '',
  clientId: '',
  googlePayMerchantId: '',
  paymentName: '',
  // sendbox values
  merchantSandbox: '',
  secretKeySandbox: '',
  solidGateKeySandbox: '',
  solidGateMerchantSandbox: '',
};

const paymentGateOptions = [
  { value: 'solid', label: 'solid' },
  { value: 'paypal', label: 'paypal' },
];

interface Props {
  credentials: CredentialsState;
  handleSaveCredentials: Function;
  handleDelete: Function;
}

const PaymentCredentialsForm: React.FC<Props> = (props: Props) => {
  const { formState, dispatch } = useFormState(INITIAL_STATE_CREDENTIAL);
  const { credentials, handleSaveCredentials, handleDelete } = props;
  const [copySuccess, setCopySuccess] = useState('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const {
      paymentGateway,
      id,
      merchant,
      secretKey,
      solidGateKey,
      solidGateMerchant,
      apiBaseUrl,
      clientId,
      wallet,
      paymentName,
      googlePayMerchantId,
      // sendbox values
      merchantSandbox,
      secretKeySandbox,
      solidGateKeySandbox,
      solidGateMerchantSandbox,
      tempId,
    } = credentials;

    dispatch({
      id,
      paymentGateway,
      wallet,
      paymentName,
      googlePayMerchantId,
      merchant,
      secretKey,
      solidGateKey,
      solidGateMerchant,
      apiBaseUrl,
      clientId,
      merchantSandbox,
      secretKeySandbox,
      solidGateKeySandbox,
      solidGateMerchantSandbox,
      tempId,
    });
  }, [credentials, dispatch]);

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.currentTarget;

    dispatch({ [name]: value });
  };

  const handleSelect = (
    selected: ValueType<OptionType>,
    e: ActionMeta<OptionType>
  ): void => {
    const { name } = e;
    if (name) {
      dispatch({ [name]: selected });
    }
  };

  const copyCredId = (): void => {
    if (credentials.id) {
      copyToClipboard(credentials.id);
      setCopySuccess('id');
      setTimeout(() => {
        setCopySuccess('');
      }, 700);
    } else {
      setError('No credentials id received');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleSaveCredentials(formState);
  };

  const deleteCred = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    handleDelete(credentials.id);
  };

  return (
    <form className="form_wrapper_left" onSubmit={handleSubmit}>
      <div className="label_row">
        <div>Payment gateway</div>
        <div className={formStyles.small_text}>Required</div>
      </div>
      <Select
        options={paymentGateOptions}
        styles={selectStyles}
        name="paymentGateway"
        value={formState.paymentGateway}
        isSearchable={false}
        onChange={handleSelect}
      />
      {credentials.id && (
        <label htmlFor="id" className={formStyles.row}>
          <div className="label">Credentials id</div>
          <div className="input_w_icon_wrapper">
            <input
              type="text"
              name="id"
              className="input_w_icon"
              value={credentials.id}
              readOnly
            />
            <button
              className="input_icn_btn"
              type="button"
              onClick={copyCredId}
            >
              <CopyIcon className="input_icon" />
            </button>
            {copySuccess === 'id' && (
              <div className={styles.tooltip}>Copied</div>
            )}
          </div>
        </label>
      )}
      <label htmlFor="wallet" className={formStyles.row}>
        <div className="label_row">
          <div>Wallet</div>
          <div className={formStyles.small_text}>Required</div>
        </div>
        <input
          type="text"
          name="wallet"
          className="input"
          value={formState.wallet}
          onChange={handleInputChange}
        />
      </label>

      {formState.paymentGateway.value === 'solid' && (
        <>
          <label htmlFor="paymentName" className={formStyles.row}>
            <div className="label_row">
              <div>Payment Name</div>
            </div>
            <input
              type="text"
              name="paymentName"
              className="input"
              value={formState.paymentName}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="googlePayMerchantId" className={formStyles.row}>
            <div className="label_row">
              <div>Google pay merchant id</div>
            </div>
            <input
              type="text"
              name="googlePayMerchantId"
              className="input"
              value={formState.googlePayMerchantId}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="merchant" className={formStyles.row}>
            <div className="label_row">
              <div>Merchant</div>
            </div>
            <input
              type="text"
              name="merchant"
              className="input"
              value={formState.merchant}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="secretKey" className={formStyles.row}>
            <div className="label_row">
              <div>Secret key</div>
            </div>
            <input
              type="text"
              name="secretKey"
              className="input"
              value={formState.secretKey}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="solidGateKey" className={formStyles.row}>
            <div className="label_row">
              <div>Solid Gate Key</div>
            </div>
            <input
              type="text"
              name="solidGateKey"
              className="input"
              value={formState.solidGateKey}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="solidGateMerchant" className={formStyles.row}>
            <div className="label_row">
              <div>Solid Gate Merchant</div>
            </div>
            <input
              type="text"
              name="solidGateMerchant"
              className="input"
              value={formState.solidGateMerchant}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="merchantSandbox" className={formStyles.row}>
            <div className="label_row">
              <div>Merchant sandbox</div>
            </div>
            <input
              type="text"
              name="merchantSandbox"
              className="input"
              value={formState.merchantSandbox}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="secretKeySandbox" className={formStyles.row}>
            <div className="label_row">
              <div>Secret key sandbox</div>
            </div>
            <input
              type="text"
              name="secretKeySandbox"
              className="input"
              value={formState.secretKeySandbox}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="solidGateKeySandbox" className={formStyles.row}>
            <div className="label_row">
              <div>Solid Gate Key Sandbox</div>
            </div>
            <input
              type="text"
              name="solidGateKeySandbox"
              className="input"
              value={formState.solidGateKeySandbox}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="solidGateMerchantSandbox" className={formStyles.row}>
            <div className="label_row">
              <div>Solid Gate Merchant Sandbox</div>
            </div>
            <input
              type="text"
              name="solidGateMerchantSandbox"
              className="input"
              value={formState.solidGateMerchantSandbox}
              onChange={handleInputChange}
            />
          </label>
        </>
      )}

      {formState.paymentGateway.value === 'paypal' && (
        <>
          <label htmlFor="apiBaseUrl" className={formStyles.row}>
            <div className="label_row">
              <div>Api Base Url</div>
            </div>
            <input
              type="text"
              name="apiBaseUrl"
              className="input"
              value={formState.apiBaseUrl}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="secretKey" className={formStyles.row}>
            <div className="label_row">
              <div>Secret key</div>
            </div>
            <input
              type="text"
              name="secretKey"
              className="input"
              value={formState.secretKey}
              onChange={handleInputChange}
            />
          </label>

          <label htmlFor="clientId" className={formStyles.row}>
            <div className="label_row">
              <div>Solid Gate Key</div>
            </div>
            <input
              type="text"
              name="clientId"
              className="input"
              value={formState.clientId}
              onChange={handleInputChange}
            />
          </label>
        </>
      )}

      {error && <div className={formStyles.error_message}>{error}</div>}

      <div className={formStyles.submit_box}>
        <button type="submit" className="btn_grn_full_width">
          Save
        </button>
      </div>
      <div className={formStyles.row}>
        <button
          type="button"
          className="btn_white_bordered"
          onClick={deleteCred}
        >
          Delete
        </button>
      </div>
    </form>
  );
};

export default PaymentCredentialsForm;
